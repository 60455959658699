<template>
  <div id="user-header" class="z-50">
    <Toolbar>
      <template #start>
        <div class="flex justify-between items-center w-full">
          <div class="flex items-center">
            <BasicButton
              v-if="user"
              icon="pi pi-bars"
              class="me-2 menu-button"
              need-icon
              variant="secondary"
              outlined
              large
              @click="showHeader = !showHeader"
            />

            <h5 class="flex items-center">
              <PageTree v-if="pageTree.length" :tree="pageTree" />
              <b id="page-title">{{ pageTitle }}</b>
            </h5>
          </div>
        </div>
      </template>
      <template #end>
        <AccountInfo v-if="user" :user="user" />
      </template>
    </Toolbar>
    <Drawer v-if="user" v-model:visible="showHeader" id="header-drawer">
      <UserNavMenu
        :user="user"
        mobile
        @toggle-header="toggleHeaderFn"
        @logout="handleLogout"
      />
    </Drawer>
  </div>
</template>

<script lang="ts" setup>
import Drawer from "primevue/drawer";
import Toolbar from "primevue/toolbar";
import { ref, computed } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import { PageTree } from "/@/vue/components/Layouts";
import { useRouterUtil, useAuthorize, useUser } from "/@/vue/composables";
import type { UserClient } from "/@/types/user";
import { UserNavMenu } from "/@/vue/components/Layouts";
import { AccountInfo } from "/@/vue/components/Organisms/Users";

interface Props {
  user?: UserClient;
  isAdminPage?: boolean;
}

const props = defineProps<Props>();
defineEmits(["logout", "hideHeader"]);

const showHeader = ref<boolean>(false);

const { goto, reload, currentRouteMeta } = useRouterUtil();

const pageTitle = computed(() => {
  /*
  if (currentRouteQuery.value?.title) {
    return currentRouteQuery.value.title;
  }
  */
  return currentRouteMeta.value?.title;
});

const pageTree = computed(() => {
  /*
  if (currentRouteQuery.value?.hoge) {
    return [
      { title: "hoge", name: "Index" }
    ]
  }
  */
  return currentRouteMeta.value?.tree || [];
});

function showHeaderFn() {
  showHeader.value = true;
}

function toggleHeaderFn() {
  showHeader.value = !showHeader.value;
}

// logout

const { logout } = useAuthorize();

async function handleLogout() {
  if (!confirm("ログアウトしますか？")) return;

  await logout();
  reload();
}
</script>

<style lang="scss"></style>
