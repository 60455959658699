<template>
  <div>
    <Menu :id="randomId" ref="menu" :model="menuItems" :popup="true" />
    <Button
      type="button"
      :icon="icon"
      aria-haspopup="true"
      :aria-controls="randomId"
      :label="label"
      :severity="severity"
      text
      class="w-full"
      @click="toggle"
    >
      <slot></slot>
    </Button>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import Menu from "primevue/menu";
import type { MenuItem } from "primevue/menuitem";

withDefaults(
  defineProps<{
    menuItems: MenuItem[];
    label?: string;
    icon?: string;
    severity?: string;
  }>(),
  {
    icon: "pi pi-ellipsis-v",
    severity: "primary",
  }
);

const randomId = "menu-" + Math.random().toString(36).slice(-8);

const menu = ref();

function toggle(event: any) {
  event.stopPropagation();
  menu.value.toggle(event);
}
</script>
