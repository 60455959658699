import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { ShiftClientScheme } from "./shift.d";
import { AttendanceClientScheme } from "./attendance.d";
import { OrganizationMemberClientScheme } from "./organizationMember.d";
import { MemberApplicationClientScheme } from "./memberApplication.d";
import { ApprovalRequestClientScheme } from "./approvalRequest.d";

export const HomeInfoScheme = z.object({
  shifts: ShiftClientScheme.array(),
  weekShifts: ShiftClientScheme.array(),
  nearShiftDates: z.string().array(),
  currentAttendance: AttendanceClientScheme.optional(),
  todayFinishedAttendances: AttendanceClientScheme.array(),
  attendances: AttendanceClientScheme.array(),
  recentlyApplications: MemberApplicationClientScheme.array(),
  recentlyRequests: ApprovalRequestClientScheme.array(),
  membersHasTodayActivities: OrganizationMemberClientScheme.array(),
  membersHasTomorrowShifts: OrganizationMemberClientScheme.array(),
});

export type HomeInfo = zod.infer<typeof HomeInfoScheme>;

export const CalendarInfoScheme = z.object({
  shifts: ShiftClientScheme.array(),
  managedMemberShifts: ShiftClientScheme.array(),
  memberApplications: MemberApplicationClientScheme.array(),
  managedMemberApplications: MemberApplicationClientScheme.array(),
});

export type CalendarInfo = zod.infer<typeof CalendarInfoScheme>;
