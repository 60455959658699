<template>
  <div
    id="app"
    class="bg-gray-50 dark:bg-gray-700 overflow-scroll"
    :style="{
      'font-size': fontSize,
    }"
  >
    <PageHeader
      v-if="isIndexPages"
      ref="header"
      :user="user"
      @logout="logout"
      class="rounde-0 sticky top-0 left-0 w-full"
    />

    <div
      class="router-view p-4"
      :style="
        isIndexPages ? `height: calc(100% - ${headerHeight})` : `height: 100%`
      "
    >
      <router-view></router-view>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref, provide, watch } from "vue";
import { useAuthorize, useUser, useRouterUtil } from "/@/vue/composables";
import { PageHeader } from "/@/vue/components/Layouts";

// font size

const fontSize = ref("0.9rem");
provide("fontSize", fontSize);

//

const { currentRouteName, currentRouteNeedLogin } = useRouterUtil();
const { getCurrentUser } = useUser();
const { data: user } = getCurrentUser();

watch(user, (u) => {
  if (u?.fontSize) {
    fontSize.value = u.fontSize;
  }
});

const isIndexPages = computed(() => {
  if (!currentRouteName.value) return false;
  if (currentRouteName.value === "UsersNew") return false;

  return !currentRouteNeedLogin.value;
});

const header = ref();

const headerHeight = computed(() => {
  if (!header.value) return "58px";
  return header.value.$el.clientHeight + "px";
});

const { logout } = useAuthorize();
</script>

<style lang="scss">
#app {
  height: 100%;
  width: 100%;

  --p-card-shadow: none;

  .p-datepicker-day-view {
    font-size: v-bind(fontSize);
  }

  button.p-button {
    font-size: v-bind(fontSize);
  }
}
</style>
