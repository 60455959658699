import Index from "./Index.vue";
import New from "./New.vue";
import Show from "./Show.vue";
import Success from "./Success.vue";

import { Router as AdminRouter, Root as AdminRoot } from "./Admin";
import {
  Router as ApprovalRequestsRouter,
  Root as ApprovalRequestsRoot,
} from "./ApprovalRequests";
import { Router as ArticleRouter, Root as ArticleRoot } from "./Articles";
import {
  Router as AttendancesRouter,
  Root as AttendancesRoot,
} from "./Attendances";
import { Router as CalendarRouter, Root as CalendarRoot } from "./Calendar";
import {
  Router as LaborManagementRouter,
  Root as LaborManagementRoot,
} from "./LaborManagement";
import {
  Router as OrganizationMemberLabelsRouter,
  Root as OrganizationMemberLabelsRoot,
} from "./OrganizationMemberLabels";
import {
  Router as OrganizationsRouter,
  Root as OrganizationsRoot,
} from "./Organizations";
import { Router as PasswordRouter, Root as PasswordRoot } from "./Password";
import { Router as PlanRouter, Root as PlanRoot } from "./Plans";
import { Router as WorkLogRouter, Root as WorkLogRoot } from "./WorkLog";

export default [
  {
    path: "",
    name: "UsersIndex",
    component: Index,
    meta: {
      title: "ホーム",
      tree: [],
    },
  },
  {
    path: "mypage",
    name: "UsersShow",
    component: Show,
    meta: {
      title: "マイページ",
      tree: [],
    },
  },
  {
    path: "new",
    name: "UsersNew",
    component: New,
    meta: {
      title: "ユーザー登録",
      dontNeedLogin: true,
    },
  },
  {
    path: "success",
    name: "UsersSuccess",
    component: Success,
    meta: {
      title: "完了",
      dontNeedLogin: true,
    },
  },
  {
    path: "admin",
    title: "管理画面",
    component: AdminRoot,
    children: [...AdminRouter],
  },
  {
    path: "approval_requests",
    title: "承認リクエスト管理",
    component: ApprovalRequestsRoot,
    children: [...ApprovalRequestsRouter],
  },
  {
    path: "articles",
    component: ArticleRoot,
    children: [...ArticleRouter],
  },
  {
    path: "attendances",
    component: AttendancesRoot,
    children: [...AttendancesRouter],
  },
  {
    path: "calendar",
    component: CalendarRoot,
    children: [...CalendarRouter],
  },
  {
    path: "labor_managements",
    component: LaborManagementRoot,
    children: [...LaborManagementRouter],
  },
  {
    path: "organizations",
    component: OrganizationsRoot,
    children: [...OrganizationsRouter],
  },
  {
    path: "organization_member_labels",
    component: OrganizationMemberLabelsRoot,
    children: [...OrganizationMemberLabelsRouter],
  },
  {
    path: "passwords",
    component: PasswordRoot,
    children: [...PasswordRouter],
  },
  {
    path: "plans",
    component: PlanRoot,
    children: [...PlanRouter],
  },
  {
    path: "work_logs",
    component: WorkLogRoot,
    children: [...WorkLogRouter],
  },
];
