<template>
  <form>
    <FormItemText
      v-model="signUpForm.name"
      :errors="errors"
      label="名前"
      form-id="name"
      class="mb-4"
    />
    <FormItemEmail
      v-model="signUpForm.email"
      :errors="errors"
      label="メール"
      form-id="email"
      form-type="email"
      class="mb-4"
    />
    <FormItemPassword
      v-model="signUpForm.password"
      :errors="errors"
      label="パスワード"
      form-id="password"
      autocomplete="new-password"
      class="mb-4"
    />
    <FormItemPassword
      v-model="signUpForm.passwordConfirmation"
      :errors="
        errors.password ? { passwordConfirmation: { _errors: [] } } : errors
      "
      label="パスワード(確認)"
      form-id="passwordConfirmation"
      autocomplete="new-password"
    />
    <div class="debug">form: {{ signUpForm }}</div>
    <div class="mt-3">
      <BasicButton button-type="submit" @click="signup">
        アカウントの作成
      </BasicButton>
    </div>
  </form>
</template>

<script setup lang="ts">
import { useZodScheme } from "/@/vue/composables/zod";
import { BasicButton } from "/@/vue/components/Atom";
import {
  FormItemText,
  FormItemEmail,
  FormItemPassword,
} from "/@/vue/components/Molecules";
import {
  User,
  SignUpParams,
  SignUpForm as SignUpFormType,
  SignUpScheme,
} from "/@/types";

//

interface Emits {
  (e: "signup", user: SignUpParams): void;
}

const emit = defineEmits<Emits>();

// form

const { useFormAndErrors } = useZodScheme();
const {
  form: signUpForm,
  errors,
  startValidation,
} = useFormAndErrors<SignUpFormType>(SignUpScheme);

function signup() {
  try {
    startValidation.value = true;

    const user = SignUpScheme.parse(signUpForm);
    emit("signup", user);
  } catch (e) {
    console.error(e);
  }
}
</script>

<style scoped></style>
