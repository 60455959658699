import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { Shift, ShiftClientScheme, ShiftScheme } from "/@/types/shift.d";
import { OrganizationClientScheme } from "/@/types/organization.d";
import { AttendanceClientScheme } from "/@/types/attendance.d";
import { MemberApplicationClientScheme } from "/@/types/memberApplication.d";

// settings

export const UserSettingsScheme = z.object({
  fontSize: z.string().optional(),
  workColor: z.string().optional(),
  restColor: z.string().optional(),
});

export const UserSettingsFormScheme = UserSettingsScheme.partial();
export type UserSettingsForm = zod.infer<typeof UserSettingsFormScheme>;

export const UserSettingsCheckScheme = UserSettingsScheme;

// labels

export const userLabelTypes = ["systemAdmin"];
export const UserLabelTypeScheme = z.enum(userLabelTypes);
export const UselLabelType = zod.infer<typeof UserLabelTypeScheme>;

export const UserLabelScheme = z.object({
  labelType: UserLabelTypeScheme,
  dependancyType: z.string().optional(),
  dependancyId: z.string().optional(),
});
export type UserLabel = zod.infer<typeof UserLabelScheme>;

export const bankAccountTypes = ["checking", "savings"] as const;
export const BankAccountTypeScheme = z.enum(bankAccountTypes);
export type BankAccountType = zod.infer<typeof BankAccountTypeScheme>;

export function bankAccountTypeLabel(type: BankAccountType) {
  switch (type) {
    case "checking":
      return "普通";
    case "savings":
      return "当座";
  }
}

export const UserScheme = z.object({
  lastName: z.string().trim().min(1).max(50),
  firstName: z.string().trim().min(1).max(50),
  lastNameKana: z.string().trim().min(1),
  firstNameKana: z.string().trim().min(1),
  postalCode: z.string().trim().min(7).max(7),
  addressLevel1: z.string().trim().min(1).max(30),
  addressLevel2: z.string().trim().min(1).max(30),
  addressLine1: z.string().trim().min(0).max(255),
  addressLine2: z.string().trim().min(0).max(255).optional().or(z.literal("")),
  nearestStation: z.string().trim().min(1).max(50),
  birthday: z.string().trim().min(5),
  phoneNumber: z.string().trim().min(10).max(11),
  email: z.string().email(),
});

export type User = zod.infer<typeof UserScheme>;
export type UserAttributes = keyof User;

export const UserClientScheme = UserScheme.extend({
  id: z.number(),
  fullName: z.string(),
  age: z.number(),
  userLabels: UserLabelScheme.array(),
  labelAttributes: z.object({
    is_system_admin: z.boolean().optional(),
  }),
  statusAttributes: z.object({
    is_working: z.boolean().optional(),
    finish_working: z.boolean().optional(),
    is_absence: z.boolean().optional(),
    has_managed_organizations: z.boolean().optional(),
  }),
  organizations: OrganizationClientScheme.array(),
  shifts: ShiftClientScheme.array(),
  currentAttendance: AttendanceClientScheme.optional(),
  todayFinishedAttendances: AttendanceClientScheme.array(),
  memberApplications: MemberApplicationClientScheme.array(),
  fontSize: z.string().optional(),
  workColor: z.string().optional(),
  restColor: z.string().optional(),
});

export type UserClient = zod.infer<typeof UserClientScheme>;

export const UserFormScheme = UserClientScheme.partial().extend({
  shifts: ShiftScheme.array(),
});
export type UserForm = zod.infer<typeof UserFormScheme>;

// session

type SingUpResponse = {
  status: "confirm" | "success" | "error";
  user?: User;
};

export const LoginScheme = z.object({
  email: z.string().email(),
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
});

export const LoginFormScheme = LoginScheme.partial();
export type LoginForm = zod.infer<typeof LoginFormScheme>;

// signup

export const SignUpParamsScheme = UserScheme.extend({
  requesterId: z.number().optional(),
  password: z.string().min(6, { message: "6文字以上である必要があります" }),
  passwordConfirmation: z.string(),
});

export type SignUpParams = zod.infer<typeof SignUpParamsScheme>;

export const SignUpFormScheme = SignUpParamsScheme.partial();
export type SignUpForm = zod.infer<typeof SignUpFormScheme>;

export const SignUpScheme = SignUpParamsScheme.refine(
  (data) => data.password === data.passwordConfirmation,
  {
    message: "パスワードが一致していません",
    path: ["passwordConfirmation"], // path of error
  }
);

// password

// change password

export const PasswordChangeScheme = z.object({
  password: z.string().min(1, { message: "パスワードが入力されていません" }),
  passwordConfirmation: z
    .string()
    .min(1, { message: "パスワードが入力されていません" }),
});

export type PasswordChange = zod.infer<typeof PasswordChangeScheme>;

export const PasswordChangeCheckScheme = PasswordChangeScheme.refine(
  (data) => data.password === data.passwordConfirmation,
  {
    message: "パスワードが一致しません",
    path: ["passwordConfirmation"],
  }
);

export const PasswordChangeFormScheme = PasswordChangeScheme.partial();
export type PasswordChangeForm = zod.infer<typeof PasswordChangeFormScheme>;
