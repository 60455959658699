<template>
  <div>
    <div class="flex justify-end w-full my-2">
      <BasicButton
        label="戻る"
        class="me-2"
        @click="goto({ name: 'UsersArticlesIndex' })"
      />
    </div>

    <ArticleTable
      :articles="articles?.items || []"
      :total-pages="articles?.totalPages || 0"
      v-model:per-page="selectedPerPage"
      v-model:current-page="currentPage"
      @select-article="selectArticle"
      @trash-article="handleDestroyArticle"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useRouterUtil, useArticle, useTableHeader } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { Table as ArticleTable } from "/@/vue/components/Organisms/Articles";

const { goto } = useRouterUtil();

const { currentPage, selectedPerPage } = useTableHeader();

const { getTrashedArticles, destroyArticle } = useArticle();
const { data: articles, mutate: getArticlesMutate } = getTrashedArticles(
  currentPage,
  selectedPerPage
);

function selectArticle(id: number) {
  // console.log(id);
}

async function handleDestroyArticle(id: number | number[]) {
  if (await destroyArticle(id)) {
    getArticlesMutate();
  } else {
    alert("削除に失敗しました");
  }
}
</script>

<style scoped></style>
