<template>
  <div>
    <div class="flex justify-end w-full mb-4">
      <BasicButton
        label="編集"
        variant="secondary"
        outlined
        @click="goto({ name: 'UsersArticlesEdit', params: { id: id } })"
      />
    </div>

    <ArticleShow v-if="article" :article="article" is-user-page />

    <div class="flex flex-wrap justify-center my-5">
      <BasicButton
        label="戻る"
        variant="secondary"
        class="me-5"
        outlined
        @click="backto({ confirm: false })"
      />
      <BasicButton
        v-if="canEdit"
        label="編集"
        variant="secondary"
        outlined
        @click="goto({ name: 'UsersArticlesEdit', params: { id: id } })"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useArticle, useRouterUtil, useUser } from "/@/vue/composables";
import { Show as ArticleShow } from "/@/vue/components/Organisms/Articles";
import { BasicButton } from "/@/vue/components/Atom";

const { getArticle } = useArticle();
const { currentRouteParams, goto, backto } = useRouterUtil();

const id = computed(() => {
  return Number(currentRouteParams.value.id as string);
});

const { data: article } = getArticle(id, { admin: true });

// user

const { getCurrentUser, canEdit } = useUser();
const { data: user } = getCurrentUser();
</script>

<style scoped></style>
