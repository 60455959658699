<template>
  <div>
    <Card>
      <template #content>
        <div
          class="flex flex-col items-center justify-center h-full w-full"
          style="min-height: 500px"
        >
          <p class="text-lg mb-4 flex flex-col justify-center">
            <span v-for="m in messages">
              {{ m }}
            </span>
          </p>
          <BasicButton label="ホームに戻る" @click="gotoHome" />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import Card from "primevue/card";

const { currentRouteQuery, goto } = useRouterUtil();

const messages = computed(() => {
  if (currentRouteQuery.value.fromUsersNew) {
    return [
      "アカウントの作成が完了しました。",
      "メールアドレス宛にメールを送信しましたので、確認しメールの認証をおこなってください。",
    ];
  } else {
    return ["処理が完了しました"];
  }
});

function gotoHome() {
  goto({ name: "UsersIndex" });
}
</script>

<style scoped></style>
