<template>
  <div>
    <form @submit.prevent="submit">
      <FormItemPassword
        v-model="form.password"
        :errors="errors"
        label="新しいパスワード(半角英数字6文字以上)"
        form-id="password"
        class="mb-4"
        show-error-message
      />
      <FormItemPassword
        v-model="form.passwordConfirmation"
        :errors="errors"
        label="パスワード（確認）"
        form-id="passwordConfirmation"
        show-error-message
      />
      <div class="mb-4"></div>
      <div v-if="!loading" class="flex mt-3 w-full">
        <BasicButton
          class="me-3"
          label="戻る"
          @click="backto({ confirm: false })"
        />
        <BasicButton
          class="ms-3"
          color="green"
          label="更新"
          button-type="submit"
          :disabled="loading"
          @click="submit"
        />
      </div>

      <i v-else class="pi pi-spin pi-spinner"></i>
    </form>
  </div>
</template>

<script setup lang="ts">
import { useZodScheme, useRouterUtil } from "/@/vue/composables";
import { BasicButton } from "/@/vue/components/Atom";
import { FormItemText, FormItemPassword } from "/@/vue/components/Molecules";
import {
  PasswordChange,
  PasswordChangeForm,
  PasswordChangeCheckScheme,
} from "/@/types";

interface Props {
  loading?: boolean;
}

interface Emits {
  (e: "submit", form: PasswordChange): void;
}

defineProps<Props>();
const emit = defineEmits<Emits>();

//

const { backto } = useRouterUtil();

// form

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<PasswordChangeForm>(
  PasswordChangeCheckScheme
);

function submit() {
  try {
    startValidation.value = true;
    const validForm = PasswordChangeCheckScheme.parse(form);
    emit("submit", validForm);
  } catch (e) {
    console.error("");
  }
}
</script>

<style scoped></style>
