import { EventApi } from "@fullcalendar/core";
import { DateForCalendar, DateMap } from "/@/types";
import { createRangeFromLuxons, fromISO, fromObject } from "./luxon";

// event

export function createCalendarEvent(args: Partial<EventApi>[]) {
  return {
    classNames: ["cursor-pointer"],
    ...args,
  };
}

// datemap

export function createDateForCalendars(dateMap: DateMap): DateForCalendar[] {
  const startOn = fromObject({
    year: dateMap.year,
    month: dateMap.month,
    day: 1,
  });

  if (!startOn) {
    return [];
  }

  const finishOn = startOn.endOf("month");
  const range = createRangeFromLuxons(startOn, finishOn, "day");

  return range.map((dt) => ({
    weekday: dt.weekday,
    dayNumber: dt.day,
    dateKey: dt.toFormat("yyyy-MM-dd"),
    luxonDate: dt,
  }));
}

export function isoToDateMap(iso: string): DateMap {
  const dt = fromISO(iso);

  if (!dt || dt.invalidReason) {
    throw new Error(
      `isoToDateMap received unprocessable value. ${
        dt ? "Invalid reason: " + dt.invalidReason : ""
      }`
    );
  }

  return { year: dt.year, month: dt.month };
}

export function createExistsDateMap(isos: string[]): DateMap[] {
  const sorted = isos.sort();
  return sorted.map((iso) => isoToDateMap(iso));
}
