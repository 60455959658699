<template>
  <div>
    <Dialog
      :visible="visible"
      :header="title"
      modal
      dismissable-mask
      block-scroll
      :style="{
        width: '90%',
        'max-width': sizeMap[size],
      }"
      @after-hide="$emit('afterHide')"
      @update:visible="visible = $event"
    >
      <slot></slot>
    </Dialog>
  </div>
</template>

<script setup lang="ts">
import Dialog from "primevue/dialog";
import { computed } from "vue";

const visible = defineModel<boolean | undefined>("visible");

const props = withDefaults(
  defineProps<{
    title: string;
    loading?: boolean;
    size?: "small" | "medium" | "large";
  }>(),
  {
    size: "medium",
  }
);

const sizeMap = computed(() => ({
  small: "500px",
  medium: "800px",
  large: "1200px",
}));

defineEmits<{
  (e: "cancel", id: number): void;
  (e: "afterHide"): void;
}>();
</script>

<style scoped></style>
