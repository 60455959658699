<template>
  <div>
    <div class="mb-4">
      <FormLabel label="本日の出来" />
      <Select
        v-model="evaluationType"
        :options="evaluationTypes"
        :option-label="evaluationTypeLabel"
        class="w-100"
      />
    </div>

    <div class="mb-4">
      <FormLabel label="日報" />
      <FormItemTextarea
        v-model="report"
        :errors="errors"
        form-id="report"
        form-type="text"
        :rows="3"
      />
    </div>

    <Card>
      <template #content>
        <Accordion value="0">
          <AccordionPanel value="0" class="!border-0">
            <AccordionHeader>業務時間の修正</AccordionHeader>
            <AccordionContent>
              <div v-if="!fromEditModal" class="flex items-center mb-4">
                <Checkbox
                  v-model="submitCorrectionRequest"
                  binary
                  class="me-4"
                />
                <FormLabel
                  label="業務時間の修正をリクエストする"
                  without-padding
                />
              </div>

              <div v-if="currentAttendance.finishAt" class="mb-4">
                現在の業務時間:
                {{ basicFormatter(currentAttendance.startAt, "withTime") }}〜{{
                  basicFormatter(currentAttendance.finishAt, "withTime")
                }}
              </div>
              <div v-else class="mb-4">
                <p>
                  現在の業務時間:
                  {{
                    basicFormatter(currentAttendance.startAt, "withTime")
                  }}〜{{
                    basicFormatter(today, "withTime")
                  }}(ページを開いた時点)
                </p>
              </div>

              <div v-if="submitCorrectionRequest" class="">
                <FormLabel label="修正後開始時間" />
                <div class="flex mb-4">
                  <FormItemDate
                    v-model="startAt"
                    :errors="errors"
                    form-id="startAt"
                    :disabled="!fromEditModal && !submitCorrectionRequest"
                    class="me-4"
                  />
                  <FormItemTime
                    v-model="startAt"
                    :errors="errors"
                    form-id="startAt"
                    :minutes-increment="10"
                    :disabled="!fromEditModal && !submitCorrectionRequest"
                  />
                </div>
                <div class="flex">
                  <FormItemDate
                    v-model="finishAt"
                    :errors="errors"
                    form-id="finishAt"
                    :disabled="!fromEditModal && !submitCorrectionRequest"
                    class="me-4"
                  />
                  <FormItemTime
                    v-model="finishAt"
                    :errors="errors"
                    form-id="finishAt"
                    :minutes-increment="10"
                    :disabled="!fromEditModal && !submitCorrectionRequest"
                  />
                </div>
              </div>
            </AccordionContent>
          </AccordionPanel>
        </Accordion>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { basicFormatter, isSameDate, luxonNow } from "/@/modules/luxon";
import { Label as FormLabel } from "/@/vue/components/Atom";
import {
  FormItemTime,
  FormItemTextarea,
  FormItemDate,
} from "/@/vue/components/Molecules/Form";
import {
  ZodFormattedErrors,
  Attendance,
  evaluationTypes,
  evaluationTypeLabel,
} from "/@/types";
import Card from "primevue/card";
import Select from "primevue/select";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import Checkbox from "primevue/checkbox";

const evaluationType =
  defineModel<Attendance["evaluationType"]>("evaluationType");
const startAt = defineModel<Attendance["startAt"]>("startAt");
const finishAt = defineModel<Attendance["finishAt"]>("finishAt");
const report = defineModel<Attendance["report"]>("report");
const submitCorrectionRequest = defineModel<boolean>(
  "submitCorrectionRequest",
  {
    default: false,
  }
);

const today = luxonNow();

defineProps<{
  currentAttendance: Attendance;
  fromEditModal?: boolean;
  errors: ZodFormattedErrors;
}>();
</script>

<style scoped></style>
