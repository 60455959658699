import useSWRV from "swrv";
import { axios, fetcher } from "/@/modules/axios";
import { errorHandle } from "/@/modules/error";
import { Ref } from "vue";
import { DateMap } from "/@/types";
import {
  AbsenceForm,
  AttendanceClient,
  AttendanceForm,
} from "/@/types/attendance";

export function useAttendance() {
  function getAttendances(
    organizationMemberId: Ref<number | undefined>,
    selectedDate: DateMap
  ) {
    function getKey() {
      if (!organizationMemberId.value) {
        return null;
      }

      let key = `/api/v1/organization_members/${organizationMemberId.value}/attendances`;

      if (selectedDate.year && selectedDate.month) {
        key += `?year=${selectedDate.year}&month=${selectedDate.month}`;
      }

      return key;
    }

    return useSWRV<AttendanceClient[]>(() => getKey(), fetcher);
  }

  async function start(prms: {
    organizationMemberId: number;
    startAt?: string;
    startLat?: number;
    startLng?: number;
  }) {
    try {
      await axios.post(
        `/api/v1/organization_members/${prms.organizationMemberId}/attendances`,
        {
          attendance: {
            organizationMemberId: prms.organizationMemberId,
            startLat: prms.startLat,
            startLng: prms.startLng,
          },
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function finish(prms: AttendanceForm) {
    try {
      await axios.post(`/api/v1/attendances/${prms.id}/finish`, {
        attendance: prms,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function absence(organization_member_id: number, prms: AbsenceForm) {
    try {
      await axios.post(
        `/api/v1/organization_members/${organization_member_id}/attendances/absence`,
        {
          attendance: prms,
        }
      );
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  async function update(form: AttendanceForm) {
    try {
      await axios.patch(`/api/v1/attendances/${form.id}`, {
        attendance: form,
      });
      return true;
    } catch (e) {
      errorHandle(e);
      return false;
    }
  }

  return {
    getAttendances,

    start,
    finish,
    absence,
    update,
  };
}
