<template>
  <div>
    <Form
      v-model:start-at="form.startAt"
      v-model:finish-at="form.finishAt"
      :errors="errors"
      class="mb-4"
    />
    <BasicButton @click="submit">編集リクエスト</BasicButton>
  </div>
</template>

<script setup lang="ts">
import { Form } from "/@/vue/components/Organisms/WorkLogs";
import { useWorkLog, useRouterUtil, useZodScheme } from "/@/vue/composables";
import { WorkLog, WorkLogCheckScheme, WorkLogClient } from "/@/types";
import { onMounted, watch } from "vue";
import { BasicButton } from "/@/vue/components/Atom";

const { id } = useRouterUtil();

const { getWorkLog } = useWorkLog();
const { data: workLog } = getWorkLog(id);

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } = useFormAndErrors<WorkLog>(
  WorkLogCheckScheme,
  workLog.value
);

function resetForm(workLog?: WorkLogClient) {
  form.id = workLog?.id || undefined;
  form.startAt = workLog?.startAt || undefined;
  form.finishAt = workLog?.finishAt || undefined;
}

watch(workLog, (n) => {
  if (!form.id && n) {
    resetForm(n);
  }
});

onMounted(() => {
  startValidation.value = true;
  if (workLog.value) {
    resetForm(workLog.value);
  }
});

function submit() {
  // console.log("submit");
}
</script>

<style scoped></style>
