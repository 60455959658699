import zod from "zod";
import { z } from "/@/modules/zodUtils";
import { MemberApplicationClientScheme } from "/@/types/memberApplication.d";

export const approvalRequestStatus = [
  "pending",
  "approved",
  "rejected",
] as const;
export const ApprovalRequestStatusScheme = z.enum(approvalRequestStatus);
export type ApprovalRequestStatus = zod.infer<
  typeof ApprovalRequestStatusScheme
>;

export function approvalRequestStatusLabel(status: ApprovalRequestStatus) {
  switch (status) {
    case "pending":
      return "承認待";
    case "approved":
      return "承認済";
    case "rejected":
      return "拒否";
  }
}

export const approvalRequestDependancyTypes = [
  "Attendance",
  "MemberApplication",
] as const;

export const ApprovalRequestScheme = z.object({
  id: z.number().optional(),
  approvalStatus: ApprovalRequestStatusScheme,
  dependancyType: zod.string().optional(),
  dependancyId: zod.number().optional(),
  dependancy: MemberApplicationClientScheme.optional(),
  changeJson: zod.object({}).optional(),
});

export type ApprovalRequest = zod.infer<typeof ApprovalRequestScheme>;

export const ApprovalRequestClientScheme = ApprovalRequestScheme.extend({
  organizationMemberId: z.number(),
  createdAt: z.string(),
});
export type ApprovalRequestClient = zod.infer<
  typeof ApprovalRequestClientScheme
>;
