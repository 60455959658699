<template>
  <div>
    <template v-if="currentUser">
      <Card class="mb-4">
        <template #content>
          <div class="mb-4 flex justify-end">
            <BasicButton @click="openEditModal">編集</BasicButton>
          </div>
          <div class="mb-4">
            <Fieldset legend="基本情報" toggleable collapsed>
              <div class="flex flex-wrap mt-4">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="氏名" class="md:pe-4">
                    <div>
                      {{ currentUser.fullName }}
                    </div>
                  </ShowFormItemWrapper>
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="ヨミガナ" class="md:ps-4">
                    <div>
                      {{ currentUser.lastNameKana
                      }}{{ currentUser.firstNameKana }}
                    </div>
                  </ShowFormItemWrapper>
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemEmail
                    v-if="currentUser.email"
                    :email="currentUser.email"
                    class="md:pe-4"
                  />
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="生年月日" class="md:ps-4">
                    <div>
                      {{
                        basicFormatter(currentUser.birthday, "withoutDateDay")
                      }}
                      ({{ basicFormatter(currentUser.birthday, "japanize") }})
                      ({{ currentUser.age }}歳)
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>
            </Fieldset>
          </div>

          <div v-if="member" class="mb-4">
            <Fieldset legend="入退社情報" toggleable collapsed>
              <div class="flex flex-wrap mt-4">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="在籍状況" class="pe-4">
                    <div>
                      {{ member.leavingOn ? "退職済" : "在籍中" }}
                    </div>
                  </ShowFormItemWrapper>
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="入社日" class="md:ps-4">
                    <div>
                      {{ basicFormatter(member.joiningOn, "withoutDateDay") }}
                      ({{ basicFormatter(member.joiningOn, "japanize") }})
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="退社年月日" class="md:pe-4">
                    <div>
                      {{
                        member.leavingOn
                          ? `${basicFormatter(
                              member.leavingOn,
                              "withoutDateDay"
                            )}(${basicFormatter(member.leavingOn, "japanize")})`
                          : "-"
                      }}
                    </div>
                  </ShowFormItemWrapper>
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="退職事由" class="md:ps-4">
                    <div>
                      {{ member.leavingReason || "-" }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>
            </Fieldset>
          </div>

          <div v-if="member" class="mb-4">
            <Fieldset legend="業務情報" toggleable collapsed>
              <div class="flex flex-wrap mt-4">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="社員番号" class="md:pe-4">
                    <div>
                      {{ member.code }}
                    </div>
                  </ShowFormItemWrapper>
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="雇用形態" class="md:ps-4">
                    <div>
                      {{
                        member.labels
                          .filter((l) =>
                            organizationMemberLabelTypeForms.includes(
                              l.labelType
                            )
                          )
                          .map((l) =>
                            organizationMemberLabelTypeLabel(l.labelType)
                          )
                          .join(", ")
                      }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="事業所" class="md:pe-4">
                    <div></div>
                  </ShowFormItemWrapper>
                </div>

                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="給与支給形態" class="md:ps-4">
                    <div></div>
                  </ShowFormItemWrapper>
                </div>
              </div>
            </Fieldset>
          </div>

          <div class="mb-4">
            <Fieldset legend="部署・役職情報" toggleable collapsed>
              <div class="flex flex-wrap mt-4">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="部署" class="md:pe-4">
                    <div></div>
                  </ShowFormItemWrapper>
                </div>
              </div>
            </Fieldset>
          </div>

          <div class="mb-4">
            <Fieldset legend="現住所と連絡先" toggleable collapsed>
              <div class="flex flex-wrap mt-4">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemAddress
                    :postal-code="currentUser.postalCode"
                    :address-level1="currentUser.addressLevel1"
                    :address-level2="currentUser.addressLevel2"
                    :address-line1="currentUser.addressLine1"
                    :address-line2="currentUser.addressLine2"
                    :nearest-station="currentUser.nearestStation"
                    class="md:pe-4"
                  />
                </div>
                <div class="md:w-1/2 w-full">
                  <ShowFormItemTel
                    v-if="currentUser.phoneNumber"
                    :tel="currentUser.phoneNumber"
                    class="md:ps-4"
                  />
                </div>
              </div>
            </Fieldset>
          </div>

          <div v-if="member">
            <Fieldset legend="口座情報" toggleable collapsed>
              <div class="flex flex-wrap mt-4">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="銀行名" class="md:pe-4">
                    <div>
                      {{ member.bankName }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="支店名" class="md:pe-4">
                    <div>
                      {{ member.bankBranchName }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="預金種別" class="md:pe-4">
                    <div>
                      {{ bankAccountTypeLabel(member.bankAccountType) }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="口座番号" class="md:pe-4">
                    <div>
                      {{ member.bankAccountNumber }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>

              <div class="flex flex-wrap">
                <div class="md:w-1/2 w-full">
                  <ShowFormItemWrapper title="口座名義" class="md:pe-4">
                    <div>
                      {{ member.bankAccountHolder }}
                    </div>
                  </ShowFormItemWrapper>
                </div>
              </div>
            </Fieldset>
          </div>
        </template>
      </Card>

      <Card>
        <template #content>
          <div class="flex items-center">
            <label for="font-size" class="me-4"> サイズ </label>
            <SelectButton
              name="font-size"
              v-model="fontSize"
              :options="fontSizes"
              option-label="label"
              option-value="value"
              size="small"
              style="--p-togglebutton-padding: 0.25rem 0.5rem"
            />
          </div>
        </template>
      </Card>

      <SimpleModal v-model:visible="showEditModal" title="メンバー情報の編集">
        <template v-if="member">
          <div class="mb-4">
            {{ member.user.lastName }}{{ member.user.firstName }}
          </div>

          <div v-if="requester">
            <ShowFormItemWrapper title="提携元組織">
              {{ requester?.name }}
            </ShowFormItemWrapper>
          </div>

          <OrganizationMemberForm
            v-model:first-name="form.firstName"
            v-model:last-name="form.lastName"
            v-model:first-name-kana="form.firstNameKana"
            v-model:last-name-kana="form.lastNameKana"
            v-model:email="form.email"
            v-model:phone-number="form.phoneNumber"
            v-model:birthday="form.birthday"
            v-model:postal-code="form.postalCode"
            v-model:address-level1="form.addressLevel1"
            v-model:address-level2="form.addressLevel2"
            v-model:address-line1="form.addressLine1"
            v-model:address-line2="form.addressLine2"
            v-model:nearest-station="form.nearestStation"
            v-model:code="form.code"
            v-model:joining-on="form.joiningOn"
            v-model:leaving-on="form.leavingOn"
            v-model:leaving-reason="form.leavingReason"
            v-model:bank-name="form.bankName"
            v-model:bank-branch-name="form.bankBranchName"
            v-model:bank-account-type="form.bankAccountType"
            v-model:bank-account-number="form.bankAccountNumber"
            v-model:bank-account-holder="form.bankAccountHolder"
            v-model:business-accounts="form.businessAccounts"
            v-model:business-phones="form.businessPhones"
            :errors="errors"
            :startValidation="startValidation"
            class="mb-4"
          />

          <div class="flex justify-end">
            <BasicButton @click="submit">更新</BasicButton>
          </div>
        </template>
      </SimpleModal>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, Ref, watch, computed } from "vue";
import {
  useUser,
  useOrganizationMember,
  useDate,
  useZodScheme,
} from "/@/vue/composables";
import { basicFormatter } from "/@/modules/luxon";
import Card from "primevue/card";
import Fieldset from "primevue/fieldset";
import SelectButton from "primevue/selectbutton";
import { BasicButton } from "/@/vue/components/Atom";
import {
  Simple as SimpleModal,
  ShowFormItemWrapper,
  ShowFormItemTel,
  ShowFormItemAddress,
  ShowFormItemEmail,
} from "/@/vue/components/Molecules";
import { Form as OrganizationMemberForm } from "/@/vue/components/Organisms/OrganizationMembers";
import {
  bankAccountTypeLabel,
  organizationMemberLabelTypeForms,
  organizationMemberLabelTypeLabel,
  OrganizationMemberCheckScheme,
  OrganizationMemberClient,
  OrganizationMemberForm as OrganizationMemberFormType,
} from "/@/types";

const { getCurrentUser, updateSettings } = useUser();
const { data: currentUser } = getCurrentUser();

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});

const selectedOrganizationId = inject("selectedOrganizationId");
const { getOrganizationMember, updateOrganizationMember } =
  useOrganizationMember();
const { data: member } = getOrganizationMember(selectedOrganizationId);

const fontSize = inject("fontSize") as Ref<string>;

watch(fontSize, (fs) => {
  updateSettings({ fontSize: fs });
});

const fontSizes = [
  { label: "小", value: "0.8rem" },
  { label: "中", value: "1rem" },
  { label: "大", value: "1.2rem" },
];

// edit

// member select
const showEditModal = ref(false);

const requester = computed(() => {
  const rid = member.value?.requesterId;

  if (!rid) return;

  return organizations.value.find((o) => o.id === rid);
});

const { useFormAndErrors } = useZodScheme();
const { form, errors, startValidation } =
  useFormAndErrors<OrganizationMemberFormType>(OrganizationMemberCheckScheme);

function resetForm(om: OrganizationMemberClient) {
  form.id = om.id;

  form.firstName = om.user.firstName || undefined;
  form.lastName = om.user.lastName || undefined;
  form.firstNameKana = om.user.firstNameKana || undefined;
  form.lastNameKana = om.user.lastNameKana || undefined;
  form.email = om.user.email || undefined;
  form.phoneNumber = om.user.phoneNumber || undefined;
  form.birthday = om.user.birthday || undefined;
  form.postalCode = om.user.postalCode || undefined;
  form.addressLevel1 = om.user.addressLevel1 || undefined;
  form.addressLevel2 = om.user.addressLevel2 || undefined;
  form.addressLine1 = om.user.addressLine1 || undefined;
  form.addressLine2 = om.user.addressLine2 || undefined;
  form.nearestStation = om.user.nearestStation || undefined;

  form.code = om.code || undefined;
  form.joiningOn = om.joiningOn || undefined;
  form.bankName = om.bankName || undefined;
  form.bankBranchName = om.bankBranchName || undefined;
  form.bankAccountType = om.bankAccountType || undefined;
  form.bankAccountNumber = om.bankAccountNumber || undefined;
  form.bankAccountHolder = om.bankAccountHolder || undefined;
  form.businessAccounts = om.businessAccounts || undefined;
  form.businessPhones = om.businessPhones || undefined;
}

function openEditModal() {
  showEditModal.value = true;
  resetForm(member.value);
}

function closeEditModal() {
  showEditModal.value = false;
}

async function submit() {
  try {
    startValidation.value = true;

    if (!member.value) {
      alert("メンバーが選択されていません。");
      return;
    }

    const args = OrganizationMemberCheckScheme.parse(form);

    if (await updateOrganizationMember(args)) {
      alert("更新しました");
      closeEditModal();
    } else {
      alert("更新に失敗しました。");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  }
}
</script>

<style scoped></style>
