import Index from "./Index.vue";

export default [
  {
    path: "",
    name: "UsersCalendarIndex",
    component: Index,
    meta: {
      title: "出勤予定",
    },
  },
];
