import { DateTime, Settings, DateObjectUnits } from "luxon";
import { removeUndefinedFilterFn } from "/@/modules/array";

export const existsDates = [
  "2024-10-01",
  "2024-11-01",
  "2024-12-01",
  "2025-01-01",
  "2025-02-01",
  "2025-03-01",
  "2025-04-01",
  "2025-05-01",
  "2025-06-01",
];

export type LuxonTimeObject = DateObjectUnits;
/*
{
    // a year, such as 1987
    year?: number | undefined;
    // a month, 1-12
    month?: number | undefined;
    // a day of the month, 1-31, depending on the month
    day?: number | undefined;
    // day of the year, 1-365 or 366
    ordinal?: number | undefined;
    // an ISO week year
    weekYear?: number | undefined;
    // an ISO week number, between 1 and 52 or 53, depending on the year
    weekNumber?: number | undefined;
    // an ISO weekday, 1-7, where 1 is Monday and 7 is Sunday
    weekday?: number | undefined;
    // hour of the day, 0-23
    hour?: number | undefined;
    // minute of the hour, 0-59
    minute?: number | undefined;
    // second of the minute, 0-59
    second?: number | undefined;
    // millisecond of the second, 0-999
    millisecond?: number | undefined;
}
*/

export function setup() {
  Settings.defaultLocale = "ja";
  Settings.defaultZone = "Asia/Tokyo";
}

setup();

// from to

export function fromISO(iso: string) {
  const luxonDate = DateTime.fromISO(iso);

  return luxonDate;
}

export function fromDate(date: Date) {
  const l = DateTime.fromJSDate(date);

  if (!l.isValid) {
    throw new Error(`Invalid date: ${date}`);
  }

  return l;
}

export function fromObject(obj: LuxonTimeObject | undefined) {
  if (!obj) {
    return undefined;
  }

  const l = DateTime.fromObject(obj);

  if (!l.isValid) {
    throw new Error(`Invalid object: ${obj}`);
  }

  return l;
}

export function toDateFromISO(iso: string) {
  return DateTime.fromISO(iso).toJSDate();
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d = "2000-01-01T10:00:00.000+09:00";

  it("toDateFromISO", () => {
    expect(toDateFromISO(d)).toStrictEqual(new Date(Date.parse(d)));
  });
}

// format

export function isoFormat(iso: string, f: string) {
  if (!iso) {
    return null;
  }

  return DateTime.fromISO(iso).toFormat(f);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d = "2000-01-01T10:00:00.000+09:00";

  it("isoFormat", () => {
    expect(isoFormat(d, "EEE")).toBe("土");
    expect(isoFormat(d, "HH:mm")).toBe("10:00");
  });
}

export function basicFormatter(
  input: Date | string | DateTime | undefined,
  mode?:
    | "onlyDayMonth"
    | "onlyMonth"
    | "onlyDateDay"
    | "withoutDateDay"
    | "noYear"
    | "onlyWday"
    | "withTime"
    | "slashStyle"
    | "slashStyleCompact"
    | "onlyTime"
    | "iso"
    | "japanize"
) {
  if (!input) {
    return undefined;
  }

  let format = "yyyy年M月d日(EEE)";

  switch (mode) {
    case "onlyDayMonth":
      format = "M月d日";
      break;
    case "onlyMonth":
      format = "yyyy年M月";
      break;
    case "onlyDateDay":
      format = "d日(EEE)";
      break;
    case "withoutDateDay":
      format = "yyyy年M月d日";
      break;
    case "noYear":
      format = "M月d日(EEE)";
      break;
    case "slashStyle":
      format = "yyyy/M/d HH:mm";
      break;
    case "slashStyleCompact":
      format = "yyyy/M/d";
      break;
    case "onlyWday":
      format = "EEE";
      break;
    case "withTime":
      format = "yyyy年M月d日(EEE)HH:mm";
      break;
    case "onlyTime":
      format = "HH:mm";
      break;
    case "iso":
      format = "yyyy-MM-dd";
      break;
  }

  if (input instanceof Date) {
    if (mode === "japanize") {
      return input.toLocaleDateString("ja-JP-u-ca-japanese", {
        dateStyle: "long",
      });
    } else {
      return DateTime.fromJSDate(input).toFormat(format);
    }
  } else if (typeof input == "string") {
    const dt = DateTime.fromISO(input);

    if (mode === "japanize") {
      return dt.toJSDate().toLocaleDateString("ja-JP-u-ca-japanese", {
        dateStyle: "long",
      });
    } else {
      return dt.toFormat(format);
    }
  } else if (DateTime.isDateTime(input)) {
    if (mode === "japanize") {
      return input.toJSDate().toLocaleDateString("ja-JP-u-ca-japanese", {
        dateStyle: "long",
      });
    } else {
      return input.toFormat(format);
    }
  } else {
    throw new Error(`input ${input} is no match type in  basicFormatter`);
  }
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d = "2000-01-01T10:00:00.000+09:00";

  it("basic", () => {
    expect(basicFormatter(d)).toBe("2000年1月1日(土)");
    expect(basicFormatter(d, "onlyMonth")).toBe("2000年1月");
    expect(basicFormatter(d, "onlyDateDay")).toBe("1日(土)");
    expect(basicFormatter(d, "noYear")).toBe("1月1日(土)");
    expect(basicFormatter(d, "onlyWday")).toBe("土");
    expect(basicFormatter(d, "withTime")).toBe("2000年1月1日(土)10:00");
    expect(basicFormatter(d, "slashStyle")).toBe("2000/1/1 10:00");
  });

  it("can deal each type", () => {
    const luxonD = fromISO(d);

    if (luxonD) {
      expect(basicFormatter(luxonD)).toBe("2000年1月1日(土)");
      expect(basicFormatter(luxonD.toJSDate())).toBe("2000年1月1日(土)");
    }
  });
}

// compare

export function isSameDate(a: string, b: string) {
  const la = DateTime.fromISO(a).startOf("day");
  const lb = DateTime.fromISO(b).startOf("day");

  return la.hasSame(lb, "day");
}

export function isSameTime(a: string, b: string) {
  const la = DateTime.fromISO(a);
  const lb = DateTime.fromISO(b);

  return la.hasSame(lb, "hour") && la.hasSame(lb, "minute");
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  it("isSameDate", () => {
    expect(isSameDate("2022-10-31", "2022-10-31")).toBe(true);
    expect(isSameDate("2022-10-31", "2022-11-1")).toBe(false);
  });

  it("isSameTime", () => {
    expect(
      isSameTime(
        "2022-10-31T10:00:00.000+09:00",
        "2022-10-31T10:00:00.000+09:00"
      )
    ).toBe(true);
    expect(
      isSameTime(
        "2022-10-31T10:00:00.000+09:00",
        "2022-09-31T10:01:00.000+09:00"
      )
    ).toBe(false);
    expect(isSameTime("2022-10-31T10:00:00.000+09:00", "10:00")).toBe(true);
    expect(isSameTime("2022-10-31T10:00:00.000+09:00", "10:00+09:00")).toBe(
      true
    );
  });
}

export function isFuture(at: string) {
  return DateTime.now() < DateTime.fromISO(at);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  setup();

  const d1 = "2000-01-01T10:00:00.000+09:00";
  const d2 = "9999-01-01T10:00:00.000+09:00";

  it("isFuture", () => {
    expect(isFuture(d1)).toBe(false);
    expect(isFuture(d2)).toBe(true);
  });
}

// now

export function luxonNow() {
  return DateTime.now();
}

export function luxonTomorow() {
  return luxonNow().plus({ day: 1 }).startOf("day");
}

export function isoNow() {
  return DateTime.now().toISO();
}

// range

export type RangeInterval = "month" | "year" | "day";

export function createRangeFromLuxons(
  start: DateTime,
  end: DateTime,
  interval: RangeInterval
): DateTime[] {
  let cursor = start;
  const range: DateTime[] = [];

  while (cursor <= end) {
    range.push(cursor);
    cursor = cursor.plus({
      years: interval == "year" ? 1 : 0,
      months: interval == "month" ? 1 : 0,
      days: interval == "day" ? 1 : 0,
    });
  }

  return range;
}

export function createRange(
  startIso: string,
  endIso: string,
  interval: RangeInterval
): DateTime[] {
  const start = fromISO(startIso)?.startOf(interval);
  const end = fromISO(endIso)?.endOf(interval);

  if (!start || !end) {
    return [];
  }

  return createRangeFromLuxons(start, end, interval);
}

export function createIsoRange(
  startIso: string,
  endIso: string,
  interval: RangeInterval
): string[] {
  return createRange(startIso, endIso, interval)
    .map((r) => r.toISO())
    .filter(removeUndefinedFilterFn);
}

if (import.meta.vitest) {
  const { expect, it } = import.meta.vitest;

  const d1 = "2022-02-20T10:00:00.000+09:00";
  const d2 = "2022-02-24T10:00:00.000+09:00";

  it("createRange", () => {
    expect(createIsoRange(d1, d2, "day")).toStrictEqual([
      "2022-02-20T00:00:00.000+09:00",
      "2022-02-21T00:00:00.000+09:00",
      "2022-02-22T00:00:00.000+09:00",
      "2022-02-23T00:00:00.000+09:00",
      "2022-02-24T00:00:00.000+09:00",
    ]);
    expect(createRange(d1, d2, "month").map((ri) => ri.toISO())).toStrictEqual([
      "2022-02-01T00:00:00.000+09:00",
    ]);
    expect(createRange(d1, d2, "year").map((ri) => ri.toISO())).toStrictEqual([
      "2022-01-01T00:00:00.000+09:00",
    ]);
  });
}
