<template>
  <div>
    <div v-if="requester">
      <span>提携元組織 {{ requester.name }}</span>
    </div>

    <div v-if="!manager" class="mb-4">
      <Fieldset legend="基本情報" toggleable collapsed class="mb-4">
        <div class="flex items-center mb-4">
          <FormItemText
            v-model="lastName"
            :errors="errors"
            label="姓(必須)"
            form-id="lastName"
            form-type="text"
            class="me-4"
            style="max-width: 300px"
          />
          <FormItemText
            v-model="firstName"
            :errors="errors"
            label="名(必須)"
            form-id="firstName"
            form-type="text"
            class="ms-4"
            style="max-width: 300px"
          />
        </div>
        <div class="flex mb-4">
          <FormItemText
            v-model="lastNameKana"
            :errors="errors"
            label="せい(必須)"
            form-id="lastNameKana"
            form-type="text"
            class="me-4"
            style="max-width: 300px"
          />
          <FormItemText
            v-model="firstNameKana"
            :errors="errors"
            label="めい(必須)"
            form-id="firstNameKana"
            form-type="text"
            class="ms-4"
            style="max-width: 300px"
          />
        </div>
        <div class="mb-4">
          <FormLabel label="生年月日(必須)" />
          <FormItemDate
            v-model="birthday"
            :errors="errors"
            form-id="birthday"
            form-type="date"
            :start-date="fromISO('1990-01-01')"
            style="max-width: 210px"
          />
        </div>
        <FormItemEmail
          v-model="email"
          :errors="errors"
          label="メール"
          form-id="email"
          class="mb-4"
          style="max-width: 300px"
        />
      </Fieldset>
    </div>

    <div v-if="manager" class="mb-4">
      <Fieldset legend="入退社情報" toggleable collapsed>
        <div class="mb-4">
          <FormLabel label="入社日" />
          <FormItemDate
            v-model="joiningOn"
            :errors="errors"
            form-id="joiningOn"
            form-type="date"
            style="max-width: 210px"
          />
        </div>
        <div class="mb-4">
          <FormLabel label="退社日" />
          <FormItemDate
            v-model="leavingOn"
            :errors="errors"
            form-id="leavingOn"
            form-type="date"
            style="max-width: 210px"
          />
        </div>
        <div class="mb-4">
          <FormLabel label="退社理由" />
          <FormItemText
            v-model="leavingReason"
            :errors="errors"
            form-id="leavingReason"
            form-type="text"
          />
        </div>
      </Fieldset>
    </div>

    <div v-if="manager" class="mb-4">
      <Fieldset legend="業務情報" toggleable collapsed>
        <div class="mb-4">
          <FormLabel label="社員コード ※50文字以内" />
          <FormItemText
            v-model="code"
            :errors="errors"
            form-id="code"
            form-type="text"
          />
        </div>
      </Fieldset>
    </div>

    <div v-if="!manager" class="mb-4">
      <Fieldset legend="現住所と連絡先" toggleable collapsed>
        <div class="mb-4 w-full">
          <FormLabel label="郵便番号(必須) ※数字7桁" />
          <FormItemText
            v-model="postalCode"
            :errors="errors"
            form-id="postalCode"
            form-type="text"
            autocomplete="postal-code"
            style="max-width: 250px"
          />
        </div>

        <div class="flex items-center mb-4">
          <div class="me-2">
            <FormLabel label="都道府県(選択)" />
            <Select
              v-model="addressLevel1"
              :options="prefectures"
              option-label="label"
              option-value="value"
              :errors="errors"
              filter
              no-empty-label="都道府県を選択してください。"
              label="都道府県"
              form-id="area"
              :invalid="!!errors.addressLevel1"
            >
            </Select>
          </div>

          <div class="ms-2">
            <FormLabel label="市区町村(必須) " />
            <FormItemText
              v-model="addressLevel2"
              :errors="errors"
              form-id="addressLevel2"
              form-type="text"
              autocomplete="address-level2"
            />
          </div>
        </div>

        <div class="flex items-center mb-4">
          <div class="me-2">
            <FormLabel label="上記以降(必須)" />
            <FormItemText
              v-model="addressLine1"
              :errors="errors"
              form-id="addressLevel2"
              form-type="text"
              autocomplete="address-line1"
            />
          </div>

          <div class="ms-2">
            <FormLabel label="最寄りの駅名" />
            <FormItemText
              v-model="nearestStation"
              :errors="errors"
              form-id="nearestStation"
              form-type="text"
            />
          </div>
        </div>
      </Fieldset>
    </div>

    <div v-if="!manager" class="mb-4">
      <Fieldset legend="口座情報" toggleable collapsed>
        <div class="mb-4">
          <FormLabel label="銀行名 ※50文字以内" />
          <FormItemText
            v-model="bankName"
            :errors="errors"
            form-id="bankName"
            form-type="text"
          />
        </div>

        <div class="mb-4">
          <FormLabel label="支店名 ※50文字以内" />
          <FormItemText
            v-model="bankBranchName"
            :errors="errors"
            form-id="bankBranchName"
            form-type="text"
          />
        </div>

        <div class="mb-4 flex items-center">
          <div class="me-4">
            <FormLabel label="種別(選択)" />
            <Select
              v-model="bankAccountType"
              :options="bankAccountTypes"
              :option-label="(t) => bankAccountTypeLabel(t)"
              :errors="errors"
              label="種別"
              form-id="bankAccountType"
              :invalid="!!errors.bankAccountType"
            >
            </Select>
          </div>

          <div>
            <FormLabel label="番号" />
            <FormItemText
              v-model="bankAccountNumber"
              :errors="errors"
              form-id="bankAccountNumber"
              form-type="text"
              autocomplete="bankAccountNumber"
            />
          </div>
        </div>

        <div class="mb-4">
          <FormLabel label="口座名義" />
          <FormItemText
            v-model="bankAccountHolder"
            :errors="errors"
            form-id="bankAccountHolder"
            form-type="text"
            autocomplete="bankAccountHolder"
          />
        </div>
      </Fieldset>
    </div>

    <div v-if="manager" class="mb-4">
      <Fieldset legend="業務用アカウント" toggleable collapsed>
        <!-- Business Accounts -->
        <template v-for="(acc, idx) in businessAccounts" :key="acc">
          <div v-if="!acc._destroy" class="border-b-2 mb-4">
            <div class="flex justify-end mb-4">
              <BasicButton
                icon="pi pi-trash"
                variant="danger"
                @click="removeBusinessAccount(idx)"
              >
              </BasicButton>
            </div>

            <div class="mb-4">
              <FormLabel label="サービス名" />
              <FormItemText
                v-model="acc.serviceName"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].serviceName`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="メールアドレス" />
              <FormItemEmail
                v-model="acc.email"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].email`"
                form-type="email"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="パスワード" />
              <FormItemText
                v-model="acc.password"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].password`"
                form-type="text"
              />
            </div>

            <div class="flex mb-4">
              <FormItemText
                v-model="acc.lastName"
                :errors="errors"
                label="姓"
                :form-id="`businessAccounts[${idx}].lastName`"
                form-type="text"
                class="me-1"
                style="max-width: 300px"
              />
              <FormItemText
                v-model="acc.firstName"
                :errors="errors"
                label="名"
                :form-id="`businessAccounts[${idx}].firstName`"
                form-type="text"
                class="ms-1"
                style="max-width: 300px"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="生年月日" />
              <FormItemDate
                v-model="acc.birthday"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].birthday`"
                form-type="date"
                :start-date="fromISO('1990-01-01')"
                style="max-width: 210px"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="電話番号" />
              <FormItemText
                v-model="acc.phoneNumber"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].phoneNumber`"
                form-type="text"
                autocomplete="bankAccountHolder"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="アカウント作成日" />
              <FormItemDate
                v-model="acc.createdOn"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].createdOn`"
                form-type="date"
                style="max-width: 210px"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="アカウント失効日" />
              <FormItemDate
                v-model="acc.disusedOn"
                :errors="errors"
                :form-id="`businessAccounts[${idx}].discusedOn`"
                form-type="date"
                style="max-width: 210px"
              />
            </div>

            <div class="flex justify-end mb-4">
              <BasicButton
                icon="pi pi-trash"
                variant="danger"
                @click="removeBusinessAccount(idx)"
              >
              </BasicButton>
            </div>
          </div>
        </template>

        <div class="flex justify-end mb-4">
          <BasicButton icon="pi pi-plus" @click="addBusinessAccount" />
        </div>
      </Fieldset>
    </div>
    <div v-if="manager">
      <Fieldset legend="業務用端末" toggleable collapsed>
        <!-- Business Accounts -->
        <template v-for="(phone, idx) in businessPhones" :key="phone">
          <div v-if="!phone._destroy" class="border-b-2 mb-4">
            <div class="mb-4">
              <FormLabel label="貸与日" />
              <FormItemDate
                v-model="phone.startOn"
                :errors="errors"
                :form-id="`businessPhone[${idx}].startOn`"
                form-type="date"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="返却日" />
              <FormItemDate
                v-model="phone.returnOn"
                :errors="errors"
                :form-id="`businessPhone[${idx}].returnOn`"
                form-type="date"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="sim契約日" />
              <FormItemDate
                v-model="phone.simStartOn"
                :errors="errors"
                :form-id="`businessPhone[${idx}].simStartOn`"
                form-type="date"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="最低利用日" />
              <FormItemDate
                v-model="phone.leastOn"
                :errors="errors"
                :form-id="`businessPhone[${idx}].leastOn`"
                form-type="date"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="番号" />
              <FormItemText
                v-model="phone.phoneNumber"
                :errors="errors"
                :form-id="`businessPhone[${idx}].phoneNumber`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="sim" />
              <Select
                v-model="phone.simType"
                :options="simTypes"
                :errors="errors"
                :form-id="`businessPhone[${idx}].simType`"
                :invalid="!!errors.simType"
              >
              </Select>
            </div>

            <div class="mb-4">
              <FormLabel label="会社" />
              <Select
                v-model="phone.simCompany"
                :options="simCompanyTypes"
                :errors="errors"
                :form-id="`businessPhone[${idx}].simCompany`"
                :invalid="!!errors.simCompany"
              >
              </Select>
            </div>

            <div class="mb-4">
              <FormLabel label="契約" />
              <Select
                v-model="phone.simContractType"
                :options="simContractTypes"
                :option-label="(t) => simContractTypeLabel(t)"
                :errors="errors"
                :form-id="`businessPhone[${idx}].simContractType`"
                :invalid="!!errors.simContractType"
              >
              </Select>
            </div>

            <div class="mb-4">
              <FormLabel label="契約内容" />
              <FormItemText
                v-model="phone.simContractDetails"
                :errors="errors"
                :form-id="`businessPhone[${idx}].simContractDetails`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="付与端末" />
              <FormItemText
                v-model="phone.deviceType"
                :errors="errors"
                :form-id="`businessPhone[${idx}].deviceType`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="容量" />
              <FormItemText
                v-model="phone.deviceCapacity"
                :errors="errors"
                :form-id="`businessPhone[${idx}].deviceCapacity`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="色" />
              <FormItemText
                v-model="phone.deviceColor"
                :errors="errors"
                :form-id="`businessPhone[${idx}].deviceColor`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="IMEI" />
              <FormItemText
                v-model="phone.imei"
                :errors="errors"
                :form-id="`businessPhone[${idx}].imei`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="ケース" />
              <FormItemText
                v-model="phone.case"
                :errors="errors"
                :form-id="`businessPhone[${idx}].case`"
                form-type="text"
              />
            </div>

            <div class="mb-4">
              <FormLabel label="パスコード" />
              <FormItemText
                v-model="phone.passcode"
                :errors="errors"
                :form-id="`businessPhone[${idx}].passcode`"
                form-type="text"
              />
            </div>

            <div class="flex justify-end mb-4">
              <BasicButton
                icon="pi pi-trash"
                variant="danger"
                @click="removeBusinessPhone(idx)"
              >
              </BasicButton>
            </div>
          </div>
        </template>
        <div class="flex justify-end mb-4">
          <BasicButton icon="pi pi-plus" @click="addBusinessPhone" />
        </div>
      </Fieldset>
    </div>
  </div>
</template>

<script setup lang="ts">
import { fromISO } from "/@/modules/luxon";
import { prefectures } from "/@/modules/address";
import { Label as FormLabel, BasicButton } from "/@/vue/components/Atom";
import {
  FormItemText,
  FormItemEmail,
  FormItemDate,
} from "/@/vue/components/Molecules/Form";
import {
  bankAccountTypes,
  BusinessPhone,
  simCompanyTypes,
  simContractTypeLabel,
  simContractTypes,
  simTypes,
} from "/@/types/organizationMember.d";
import {
  bankAccountTypeLabel,
  ZodFormattedErrors,
  BusinessAccount,
  OrganizationClient,
} from "/@/types/";
import Select from "primevue/select";
import Fieldset from "primevue/fieldset";

// user

const lastName = defineModel<string>("lastName");
const firstName = defineModel<string>("firstName");
const lastNameKana = defineModel<string>("lastNameKana");
const firstNameKana = defineModel<string>("firstNameKana");
const birthday = defineModel<string>("birthday");
const email = defineModel<string>("email");
const postalCode = defineModel<string>("postalCode");
const addressLevel1 = defineModel<string>("addressLevel1");
const addressLevel2 = defineModel<string>("addressLevel2");
const addressLine1 = defineModel<string>("addressLine1");
const addressLine2 = defineModel<string>("addressLine2");
const nearestStation = defineModel<string>("nearestStation");

// organizationMember

const code = defineModel<string | undefined>("code");
const joiningOn = defineModel<string | undefined>("joiningOn");
const leavingOn = defineModel<string | undefined>("leavingOn");
const leavingReason = defineModel<string | undefined>("leavingReason");
const bankName = defineModel<string | undefined>("bankName");
const bankBranchName = defineModel<string | undefined>("bankBranchName");
const bankAccountType = defineModel<string | undefined>("bankAccountType");
const bankAccountNumber = defineModel<string | undefined>("bankAccountNumber");
const bankAccountHolder = defineModel<string | undefined>("bankAccountHolder");

const businessAccounts = defineModel<BusinessAccount[]>("businessAccounts", {
  default: () => [
    {
      serviceName: "",
      email: "",
      password: "",
      lastName: "",
      firstName: "",
      birthday: "",
      phoneNumber: "",
      createdOn: "",
      disusedOn: "",
    },
  ],
});

const businessPhones = defineModel<BusinessPhone[]>("businessPhones", {
  default: () => [
    {
      startOn: "",
      returnOn: "",
      simStartOn: "",
      leastOn: "",
      phoneNumber: "",
      simType: simTypes[0],
      simCompany: simCompanyTypes[0],
      simContractType: simContractTypes[0],
      simContractDetails: "",
      deviceType: "",
      deviceCapacity: "",
      deviceColor: "",
      imei: "",
      case: "",
      passcode: "",
    },
  ],
});

defineProps<{
  requester?: OrganizationClient;
  manager?: boolean;
  errors: ZodFormattedErrors;
}>();

function addBusinessAccount() {
  businessAccounts.value.push({
    serviceName: "",
    email: "",
    password: "",
    lastName: "",
    firstName: "",
    birthday: "",
    phoneNumber: "",
    createdOn: "",
    disusedOn: "",
  });
}

function removeBusinessAccount(idx: number) {
  // businessAccounts.value.splice(idx, 1);
  businessAccounts.value = businessAccounts.value.map((acc, i) => {
    if (i === idx) {
      return {
        ...acc,
        _destroy: true,
      };
    }
    return acc;
  });
}

function addBusinessPhone() {
  businessPhones.value.push({
    startOn: "",
    returnOn: "",
    simStartOn: "",
    leastOn: "",
    phoneNumber: "",
    simType: simTypes[0],
    simCompany: simCompanyTypes[0],
    simContractType: simContractTypes[0],
    simContractDetails: "",
    deviceType: "",
    deviceCapacity: "",
    deviceColor: "",
    imei: "",
    case: "",
    passcode: "",
  });
}

function removeBusinessPhone(idx: number) {
  businessPhones.value = businessPhones.value.map((phone, i) => {
    if (i === idx) {
      return {
        ...phone,
        _destroy: true,
      };
    }
    return phone;
  });
}
</script>

<style scoped></style>
