<template>
  <div>
    <SelectDate
      v-model:year="selectedDate.year"
      v-model:month="selectedDate.month"
      :existsDates="existsDates"
      class="mb-4"
    />

    <div class="relative overflow-x-auto mb-4">
      <table
        class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400"
      >
        <thead
          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
        >
          <tr>
            <th>タイトル</th>
            <th
              v-for="d in dates"
              scope="col"
              class="px-6 py-3"
              :class="{
                'text-red-500': d.weekday === 1,
                'text-blue-500': d.weekday === 7,
              }"
            >
              {{ d.luxonDate.toFormat("d") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="w in selectedMonthWorks"
            class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
            :key="w.id"
          >
            <th>
              <span class="text-sm font-medium text-gray-900 dark:text-white">{{
                w.title
              }}</span>
            </th>
            <td
              v-for="d in dates"
              scope="row"
              class="font-medium text-gray-900 whitespace-nowrap dark:text-white"
            >
              <template v-for="wi in w.workItems" :key="wi.id">
                <div
                  v-if="wi.targetDate === d.dateKey"
                  class="cursor-pointer p-1"
                  :class="{
                    'bg-red-500': wi.needMembers != wi.members.length,
                  }"
                  @click="openModal(wi)"
                >
                  <draggable
                    :key="wi.id"
                    v-model="wi.members"
                    group="members"
                    @start="($event) => startDrag($event)"
                    @end="endDrag"
                    item-key="id"
                    class="flex flex-wrap"
                  >
                    <template #item="{ element }">
                      <transition name="fade">
                        <span
                          class="inline-block mx-1 px-1 text-xs leading-5 text-white bg-blue-500 cursor-pointer"
                          :class="{
                            '!bg-red-500':
                              wi.members.filter((m) => m.id === element.id)
                                .length > 1,
                          }"
                          @click="removeMember(wi, element)"
                        >
                          {{ element.name }}
                        </span>
                      </transition>
                    </template>
                  </draggable>
                  <div class="flex flex-row justify-end">
                    <span class="text-xs"
                      >{{ wi.members.length }}/{{ wi.needMembers }}</span
                    >
                  </div>
                </div>
              </template>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="flex flex-row justify-end">
      <div class="">
        <div class="flex justify-end">
          <BasicButton :disabled="cannotUpdate" @click="saveSchedule">
            公開
          </BasicButton>
        </div>

        <span v-if="cannotUpdate" class="text-red-500">
          問題があるため公開できません
        </span>
      </div>
    </div>

    <SimpleModal v-model:visible="modalVisible" title="メンバーの追加">
      <div class="flex flex-col p-4">
        <Select
          v-model="selectedMember"
          :options="members"
          optionLabel="name"
          placeholder="メンバーを選択"
          class="mb-4"
        />

        <BasicButton :disabled="!selectedMember" @click="addMember"
          >追加</BasicButton
        >
      </div>
    </SimpleModal>
  </div>
</template>

<script setup lang="ts">
import Draggable from "vuedraggable";
import { computed, reactive, ref } from "vue";
import { BasicButton, SelectDate } from "/@/vue/components/Atom";
import { Simple as SimpleModal } from "/@/vue/components/Molecules";
import { DateMap } from "/@/types";
import { luxonNow, existsDates } from "/@/modules/luxon";
import { createDateForCalendars } from "/@/modules/calendar";
import { range } from "lodash";
import Select from "primevue/select";

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

const dates = computed(() => {
  return createDateForCalendars(selectedDate);
});

type Member = {
  id: number;
  name: string;
};

type Work = {
  id: number;
  title: string;
  targetDate: string;
  workItems: {
    id: string;
    needMembers: number;
    members: Member[];
    targetDate: string;
  }[];
};

const members: Member[] = [
  { id: 1, name: "井上" },
  { id: 2, name: "中野" },
  { id: 3, name: "吉田" },
  { id: 4, name: "木戸" },
];

function createRandomWorkItems(workId: number, month: number) {
  const workItems = range(1, 32).map((id) => {
    return {
      id: `${workId}-${id}`,
      // random 1-3
      needMembers: Math.floor(Math.random() * 3),
      members: [members[Math.floor(Math.random() * members.length)]],
      targetDate: `2024-${month.toString().padStart(2, "0")}-${id
        .toString()
        .padStart(2, "0")}`,
    };
  });

  return workItems;
}

const workItems1 = createRandomWorkItems(1, 7);
const workItems2 = createRandomWorkItems(2, 8);
const workItems3 = createRandomWorkItems(3, 7);
const workItems4 = createRandomWorkItems(4, 8);

const works = ref<Work[]>([
  {
    id: 1,
    title: "江守 DHP武生",
    targetDate: "2024-07-01",
    workItems: workItems1,
  },
  {
    id: 2,
    title: "江守 DHP武生",
    targetDate: "2024-08-01",
    workItems: workItems2,
  },
  {
    id: 3,
    title: "中特 DHP羽咋",
    targetDate: "2024-07-01",
    workItems: workItems3,
  },
  {
    id: 4,
    title: "中特 DHP羽咋",
    targetDate: "2024-08-01",
    workItems: workItems4,
  },
]);

const selectedMonthWorks = computed<Work[]>(() => {
  return works.value.filter((w) => {
    return (
      w.targetDate ===
      `${selectedDate.year}-${selectedDate.month
        .toString()
        .padStart(2, "0")}-01`
    );
  });
});

// modal

const selectedWorkItemId = ref<string>();
const selectedWorkItem = computed(() => {
  return selectedMonthWorks.value
    .map((w) => w.workItems)
    .flat()
    .find((wi) => wi.id === selectedWorkItemId.value);
});

const modalVisible = ref(false);

function openModal(wi: Work["workItems"][0]) {
  selectedWorkItemId.value = wi.id;
  modalVisible.value = true;
}

function closeModal() {
  modalVisible.value = false;
}

const selectedMember = ref<Member>();

function addMember() {
  const member = selectedMember.value;
  const workItem = selectedWorkItem.value;

  if (!member || !workItem) {
    return;
  }

  if (workItem.members.some((m) => m.id === member.id)) {
    return;
  }

  workItem.members.push(member);
  selectedMember.value = undefined;
  selectedWorkItemId.value = undefined;
  modalVisible.value = false;
}

function removeMember(workItem: Work["workItems"][0], member: Member) {
  if (!window.confirm(`${member.name}を削除しますか？`)) {
    return;
  }

  workItem.members = workItem.members.filter((m) => m.id !== member.id);
}

function startDrag(event: any) {
  // console.log("start", event);
}

function endDrag(event: any) {
  // console.log("end", event);
}

const cannotUpdate = computed(() => {
  return (
    selectedMonthWorks.value.some((w) => {
      return w.workItems.some((wi) => {
        return wi.members.length !== wi.needMembers;
      });
    }) ||
    selectedMonthWorks.value.some((w) =>
      w.workItems.some(
        (wi) => wi.members.length !== new Set(wi.members.map((m) => m.id)).size
      )
    )
  );
});

function saveSchedule() {}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  will-change: opacity;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
