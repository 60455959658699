<template>
  <div>
    <MenuButton icon="pi pi-user" :menu-items="userMenues"> </MenuButton>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useRouterUtil, useAuthorize } from "/@/vue/composables";
import { MenuButton } from "/@/vue/components/Molecules";
import { UserClient } from "/@/types";

const props = defineProps<{
  user: UserClient;
}>();

const { goto, reload } = useRouterUtil();

const { logout } = useAuthorize();

async function handleLogout() {
  if (!confirm("ログアウトしますか？")) return;

  await logout();
  reload();
}

const userMenues = computed(() => {
  return [
    {
      label: props.user.fullName,
      items: [
        {
          label: "マイページ",
          icon: "pi pi-user",
          command: () => {
            goto({ name: "UsersShow" });
          },
        },
        {
          label: "ログアウト",
          icon: "pi pi-sign-out",
          command: () => {
            handleLogout();
          },
        },
      ],
    },
  ];
});
</script>

<style scoped></style>
