<template>
  <div>
    <Loading :loading="loading" />
    <div class="flex items-center mb-4">
      <SelectDate
        v-model:year="selectedDate.year"
        v-model:month="selectedDate.month"
        :existsDates="existsDates"
        class="me-2"
      />
    </div>

    <div>
      <div v-if="isManager"></div>

      <Card>
        <template #content>
          <div class="flex flex-wrap items-center mb-4">
            <SelectButton
              v-model="selectedApprovalStatuses"
              :options="
                approvalRequestStatus.map((s) => ({
                  label: approvalRequestStatusLabel(s),
                  value: s,
                }))
              "
              optionValue="value"
              optionLabel="label"
              multiple
              aria-labelledby="multiple"
              class="me-4"
            />
            <SelectButton
              v-model="selectedDependancyTypes"
              :options="optionsDependancyTypes"
              optionValue="value"
              optionLabel="label"
              multiple
              aria-labelledby="multiple"
            />
          </div>

          <ApprovalRequestCard
            v-for="(ar, idx) in filteredApprovalRequests"
            :key="ar.id"
            :approval-request="ar"
            :readonly="!isManager"
            @approve="approve"
            @reject="reject"
            :class="{
              'mb-4': idx !== filteredApprovalRequests.length - 1,
            }"
          />
        </template>
      </Card>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive, inject } from "vue";
import { luxonNow, existsDates } from "/@/modules/luxon";
import {
  useUser,
  useApprovalRequests,
  useMemberApplications,
} from "/@/vue/composables";
import { SelectObject, SelectDate } from "/@/vue/components/Atom";
import { Loading } from "/@/vue/components/Molecules";
import { Card as ApprovalRequestCard } from "/@/vue/components/Organisms/ApprovalRequests";
import {
  ApprovalRequestClient,
  approvalRequestStatus,
  approvalRequestStatusLabel,
  DateMap,
} from "/@/types";
import Card from "primevue/card";
import SelectButton from "primevue/selectbutton";

// date

const today = luxonNow();

const selectedDate = reactive<DateMap>({
  year: today.year,
  month: today.month,
});

// organization

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = inject("selectedOrganizationId");
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

const isManager = computed(() => selectedOrganization.value?.isManager);

//

const selectedApprovalStatuses = ref(["pending"]);

const optionsDependancyTypes = ref([
  { value: "MemberApplicationHoliday", label: "希望休申請" },
  { value: "MemberApplicationPaid", label: "有給申請" },
  { value: "MemberApplicationCorrection", label: "変更申請" },
  // { value: "User", label: "加入申請" },
]);

const selectedDependancyTypes = ref<string[]>([
  "MemberApplicationHoliday",
  "MemberApplicationPaid",
  "MemberApplicationCorrection",
  "User",
]);

const { getApprovalRequests, processApprovalRequest } = useApprovalRequests();
const { data: approvalRequests } = getApprovalRequests(
  selectedOrganizationId,
  selectedDate
);

const myRequests = computed(() => {
  if (!approvalRequests.value) return [];
  return approvalRequests.value.filter(
    (ar) =>
      ar.organizationMemberId ===
      selectedOrganization.value?.organizationMemberId
  );
});

const filteredApprovalRequests = computed(() => {
  if (!approvalRequests.value) return [];
  return approvalRequests.value.filter(
    (ar) =>
      selectedApprovalStatuses.value
        .map((aps) => aps)
        .includes(ar.approvalStatus) &&
      selectedDependancyTypes.value
        .map((dt) =>
          dt.includes("MemberApplication")
            ? dt.split("MemberApplication")[1].toLocaleLowerCase()
            : dt.toLocaleLowerCase()
        )
        .includes(ar.dependancy?.applicationType)
  );
});

const loading = ref(false);

async function approve(id: number) {
  if (!window.confirm("承認しますか？")) return;

  loading.value = true;

  try {
    if (await processApprovalRequest(id, "approve")) {
      alert("承認しました");
    } else {
      alert("予期せぬエラーで承認に失敗しました");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  } finally {
    loading.value = false;
  }
}

async function reject(id: number) {
  if (!window.confirm("却下しますか？")) return;

  loading.value = true;

  try {
    if (await processApprovalRequest(id, "reject")) {
      alert("却下しました");
    } else {
      alert("予期せぬエラーで却下に失敗しました");
    }
  } catch (e) {
    alert("入力に問題があります。赤枠の項目を確認して下さい。");
    console.error(e);
  } finally {
    loading.value = false;
  }
}
</script>

<style scoped></style>
