<template>
  <div>
    <div class="flex items-center mb-4">
      <SelectObject
        v-if="organizations && organizations.length"
        v-model="selectedOrganizationId"
        option-label="name"
        option-value="id"
        :options="organizations"
        style="min-width: 150px"
        class="me-2"
      />

      <Select
        v-model="selectedYear"
        :options="years"
        style="min-width: 100px"
      />
    </div>

    <Card>
      <template #title> 勤務管理 </template>
      <template #content>
        <div class="flex items-center justify-center">
          <div class="w-full">
            <DataTable :value="months" editMode="cell" scrollable>
              <Column header="月" style="min-width: 100px">
                <template #body="{ data }"> {{ data.month }} </template>
              </Column>
              <Column header="日数" style="min-width: 100px">
                <template #body="{ data }">
                  {{ data.dayCount }}
                </template>
              </Column>
              <Column header="公休日数" style="min-width: 100px">
                <template #body="{ data }">
                  {{ data.weekendCount }}
                </template>
              </Column>
              <!--
              <Column header="所定労働日数" field="laborDays">
                <template #body="{ data }">
                  {{ memberLaborInformationsMap[data.id].laborDays }}
                  <i class="pi pi-pencil"></i>
                </template>
                <template #editor="{ data, field }">
                  <InputNumber
                    :model-value="memberLaborInformationsMap[data.id].laborDays"
                    autofocus
                    class="w-fit"
                    @update:model-value="
                      updateLaborInfo($event, field, data.id)
                    "
                  />
                </template>
              </Column>
              -->

              <Column style="width: 100%" />
            </DataTable>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, reactive } from "vue";
import { luxonNow } from "/@/modules/luxon";
import { useUser } from "/@/vue/composables";
import { SelectObject } from "/@/vue/components/Atom";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Select from "primevue/select";
import Card from "primevue/card";

// date

const today = luxonNow();
const selectedYear = ref<number>(today.year);

const years = computed(() => {
  const years = [];
  for (let i = 0; i < 5; i++) {
    years.push(today.plus({ years: 1 }).minus({ years: i }).year);
  }
  return years;
});

function countWeekends(year: number, month: number) {
  let saturdays = 0;
  let sundays = 0;

  // 月の最初の日（1日目）から月の最後の日までループ
  let date = new Date(year, month - 1, 1);
  while (date.getMonth() === month - 1) {
    let dayOfWeek = date.getDay();

    if (dayOfWeek === 6) {
      saturdays++;
    } else if (dayOfWeek === 0) {
      sundays++;
    }

    // 次の日へ
    date.setDate(date.getDate() + 1);
  }

  return saturdays + sundays;
}

const months = computed(() => {
  return Array.from({ length: 12 }, (_, i) => i + 1).map((m) => {
    // weekend count = counts of holidays in the month (sat, sun, holiday)
    return {
      month: m,
      dayCount: new Date(selectedYear.value, m, 0).getDate(),
      weekendCount: countWeekends(selectedYear.value, m),
    };
  });
});

// user

const { getCurrentUser } = useUser();
const { data: currentUser } = getCurrentUser();

// organization

const organizations = computed(() => {
  if (!currentUser.value) return [];
  return currentUser.value.organizations;
});
const selectedOrganizationId = ref<number>();
const selectedOrganization = computed(() => {
  if (!organizations.value) return;
  return organizations.value.find((o) => o.id === selectedOrganizationId.value);
});

// labor information
</script>

<style scoped></style>
