import { PdfInfoScheme } from '/@/types';
<template>
  <div class="w-100">
    <div class="flex flex-col w-full h-full">
      <template
        v-for="(fileInfo, idx) in attachedFiles"
        :key="fileInfo.signedId"
      >
        <div class="p-2 mb-4 h-full border">
          <a
            :href="fileInfo.url"
            target="_blank"
            style="border-color: var(--p-content-border-color)"
          >
            <div class="flex mb-4">
              <div class="flex items-center me-2">
                <span class="me-1">添付{{ idx + 1 }}</span>
                <span>{{ fileInfo.filename }}</span>
                <i class="pi pi-external-link ms-2"></i>
                <span>（クリックで別タブで開きます）</span>
              </div>
              <BasicButton
                v-if="showDeleteButton"
                variant="danger"
                class="ms-2"
                icon="pi pi-trash"
                @click="$emit('remove', fileInfo)"
              />
            </div>

            <div class="flex justify-center items-center w-full">
              <embed
                v-if="fileInfo.contentType === 'application/pdf'"
                :src="fileInfo.url"
                :type="fileInfo.contentType"
                :style="pdfStyle"
              />
              <img
                v-if="
                  fileInfo.contentType === 'image/jpeg' ||
                  fileInfo.contentType === 'image/png'
                "
                :src="fileInfo.url"
                :style="{
                  height: '200px',
                  width: 'auto',
                }"
              />
            </div>
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { BasicButton } from "/@/vue/components/Atom";
import { FileInfo } from "/@/types";

defineProps<{
  attachedFiles: FileInfo[];
  showDeleteButton?: boolean;
}>();

defineEmits<{
  (e: "remove", pdf: FileInfo): void;
}>();

const pdfStyle = {
  height: "100%",
  "min-height": "700px",
  "min-width": "800px",
};
</script>

<style scoped></style>
