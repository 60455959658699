<template>
  <div>
    <div
      class="inline-block mx-1 px-1 text-xs leading-5 text-white cursor-pointer rounded"
      :class="{
        'bg-orange-500': !isHoliday,
        'bg-red-500': isHoliday,
      }"
      @click="
        {
        }
      "
    >
      <div class="flex flex-col">
        <span>{{ shiftTypeLabel(shift.shiftType) }}</span>
        <span v-if="displayMode === 'normal'" style="font-size: 9px">
          <span v-if="shift.startAt">
            {{ basicFormatter(shift.startAt, "onlyTime") }}〜{{
              basicFormatter(shift.finishAt, "onlyTime")
            }}
          </span>
        </span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Shift, shiftTypeLabel } from "/@/types";
import { basicFormatter } from "/@/modules/luxon";

const props = withDefaults(
  defineProps<{
    shift: Shift;
    isBaseTime?: boolean;
    displayMode?: "normal" | "compact";
  }>(),
  {
    isBaseTime: false,
    displayMode: "normal",
  }
);

const isHoliday = computed(
  () =>
    props.shift.shiftType === "holiday" ||
    props.shift.shiftType === "paid_holiday" ||
    props.shift.shiftType === "half_holiday"
);
</script>

<style scoped></style>
