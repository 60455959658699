<template>
  <div class="flex justify-center items-center content-center h-full">
    <div class="min-w-96 max-w-full w-8/12">
      <FormItemEmail
        v-model="email"
        label="メールアドレス"
        :errors="{}"
        form-id="email"
        class="mb-4"
      />

      <div class="w-100 flex justify-content-center mt-5">
        <BasicButton
          class="me-3"
          label="戻る"
          @click="backto({ confirm: false })"
        />
        <BasicButton
          class="ms-3"
          label="送信"
          button-type="submit"
          :disabled="loading"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useUser, useRouterUtil } from "/@/vue/composables";
import { FormItemEmail } from "/@/vue/components/Molecules";
import { BasicButton } from "/@/vue/components/Atom";

const { backto, goto } = useRouterUtil();

const email = ref<string>("");

const loading = ref<boolean>(false);

const { sendResetPasswordEmail } = useUser();

async function submit() {
  if (!email.value) return;

  loading.value = true;

  if (await sendResetPasswordEmail(email.value)) {
    alert("パスワード再設定メールを送信しました。");
    goto({ name: "UsersIndex" });
  } else {
    alert("パスワード再設定メールの送信に失敗しました。");
  }

  loading.value = false;
}
</script>

<style scoped></style>
