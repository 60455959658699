<template>
  <div>
    <Card>
      <template #content>
        <div class="flex flex-wrap items-center">
          <Badge
            :value="approvalRequestStatusLabel(approvalRequest.approvalStatus)"
            :severity="badgeSeverity[approvalRequest.approvalStatus] || 'info'"
            class="me-4"
            :style="badgeWidth"
          />

          <template v-if="isMemberApplication">
            <span v-if="approvalRequest.dependancy?.date">
              {{ basicFormatter(approvalRequest.dependancy.date) }}
            </span>
            <span class="me-4">
              {{ memberTypeLabel(approvalRequest.dependancy.applicationType) }}
            </span>

            <span v-if="!readonly" class="me-4">
              {{ approvalRequest.dependancy.userName }}
            </span>
            <span> {{ basicFormatter(approvalRequest.createdAt) }}作成 </span>

            <div
              v-if="
                approvalRequest.dependancy?.correctionStartAt &&
                approvalRequest.dependancy?.correctionFinishAt &&
                approvalRequest.approvalStatus === 'pending'
              "
              class="flex flex-wrap w-full"
            >
              <div class="flex flex-wrap me-4 mt-4">
                <Badge
                  value="修正前"
                  severity="success"
                  class="me-4"
                  :style="badgeWidth"
                />
                <span class="me-4">
                  {{
                    evaluationTypeLabel(
                      approvalRequest.dependancy.currentEvaluationType
                    )
                  }}
                </span>
                <span class="me-4">
                  {{
                    approvalRequest.dependancy.currentReport || "(日報未記入)"
                  }}
                </span>
                <span>
                  {{
                    basicFormatter(
                      approvalRequest.dependancy.currentStartAt,
                      "withTime"
                    )
                  }}〜{{
                    basicFormatter(
                      approvalRequest.dependancy.currentFinishAt,
                      "onlyTime"
                    )
                  }}
                </span>
              </div>
              <div class="flex flex-wrap mt-4">
                <Badge
                  value="修正後"
                  severity="danger"
                  class="me-4"
                  :style="badgeWidth"
                />
                <span class="me-4">
                  {{
                    evaluationTypeLabel(
                      approvalRequest.dependancy.correctionEvaluationType
                    )
                  }}
                </span>
                <span class="me-4">
                  {{
                    approvalRequest.dependancy.correctionReport ||
                    "(日報未記入)"
                  }}
                </span>
                <span>
                  {{
                    basicFormatter(
                      approvalRequest.dependancy.correctionStartAt,
                      "withTime"
                    )
                  }}〜{{
                    basicFormatter(
                      approvalRequest.dependancy.correctionFinishAt,
                      "onlyTime"
                    )
                  }}
                </span>
              </div>
            </div>
          </template>
        </div>
        <Panel
          v-if="!readonly && approvalRequest.approvalStatus === 'pending'"
          header=" "
          toggleable
          collapsed
          style="
            --p-panel-toggleable-header-padding: 0;
            --p-panel-content-padding: 0;
            border: none;
          "
        >
          <div class="flex justify-end mt-2">
            <BasicButton
              variant="danger"
              icon="pi pi-times"
              label="拒否"
              class="me-4"
              @click="$emit('reject', approvalRequest.id)"
            />

            <BasicButton
              variant="success"
              icon="pi pi-check"
              label="承認"
              @click="$emit('approve', approvalRequest.id)"
            />
          </div>
        </Panel>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { computed, reactive } from "vue";
import { BasicButton } from "/@/vue/components/Atom";
import {
  ApprovalRequestClient,
  approvalRequestStatusLabel,
  evaluationTypeLabel,
  memberTypeLabel,
} from "/@/types";
import Badge from "primevue/badge";
import Card from "primevue/card";
import Panel from "primevue/panel";
import { basicFormatter } from "/@/modules/luxon";

const props = defineProps<{
  approvalRequest: ApprovalRequestClient;
  readonly?: boolean;
}>();

defineEmits<{
  (e: "approve", id: number): void;
  (e: "reject", id: number): void;
}>();

const badgeWidth = reactive({ width: "60px" });

const isMemberApplication = computed(() => {
  return (
    props.approvalRequest.dependancyType === "MemberApplication" &&
    props.approvalRequest.dependancy
  );
});

const isHolidayApplication = computed(() => {
  return (
    isMemberApplication.value &&
    props.approvalRequest.dependancy?.applicationType === "holiday"
  );
});

const isPaidApplication = computed(() => {
  return (
    isMemberApplication.value &&
    props.approvalRequest.dependancy?.applicationType === "paid"
  );
});

const badgeSeverity = reactive({
  pending: "warning",
  approved: "success",
  rejected: "danger",
});
</script>

<style scoped></style>
