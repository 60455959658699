<template>
  <div>
    <AttendanceDetailsCard
      v-if="currentAttendance"
      :attendances="[currentAttendance]"
    />
    <AttendanceDetailsCard
      v-if="todayFinishedAttendances.length"
      :attendances="todayFinishedAttendances"
      finished
    />
    <div v-if="todayShifts.length">
      <div class="flex items-center justify-center">
        <ShiftDetailsCard
          v-for="shift in todayShifts"
          :shift="shift"
          class="w-full"
          flatten
        />
      </div>
    </div>
    <p v-if="noStatus" class="text-center text-sm text-gray-500">
      本日のスケジュールなし
    </p>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { DetailsCard as AttendanceDetailsCard } from "/@/vue/components/Organisms/Attendances";
import { DetailsCard as ShiftDetailsCard } from "/@/vue/components/Organisms/Shifts";
import { HomeInfo } from "/@/types";

const props = defineProps<{
  homeInfo: HomeInfo;
}>();

const currentAttendance = computed(() => {
  return props.homeInfo.currentAttendance;
});

const todayFinishedAttendances = computed(() => {
  return props.homeInfo.todayFinishedAttendances;
});

const todayAttendance = computed(() => {
  return props.homeInfo.attendances;
});

const todayShifts = computed(() => {
  return props.homeInfo.shifts;
});

const noStatus = computed(() => {
  return !currentAttendance.value && !todayShifts.value.length;
});
</script>

<style scoped></style>
